<template>
  <div class="home bg-gray-50">
    <Header :checked="true" />

    <AppList :cat="cat" />
    <div class="bg-gray-100">
      <DocList :cat="cat" />
    </div>
  </div>
</template>

<script>
// Components
import Header from "@/components/Layout/Header";

import AppList from "@/components/Apps/List";
import DocList from "@/components/Docs/List";

export default {
  data() {
    return {
      cat: "teacher",
    };
  },
  components: {
    Header,
    AppList,
    DocList,
  },
};
</script>
